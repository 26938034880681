import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const Footer = () => {
    const Navigate = useNavigate ();
  return (
    <div className='footer'>
        <div className='footer_item pb-4'>
            <div className='item_flex1'>
                <div className='logo_tite'>
                    <img src='/images/logo (1).svg' alt=''/>
                    <div className='pl-2'>TECHORPHIC</div>
                </div>
                <div className='logo_content'>Techorphic is Leading web and mobile app development company<br/>Delivering emerging technology services and comprehensive software<br></br> development solutions.</div>
                <div className='social_icons'>
                <Link to="https://instagram.com/techorphic.official?igshid=MGU3ZTQzNzY="><div className='circle_w mr-3'> <img src='/images/insta.svg' alt=''/></div></Link>
                    <Link to="https://www.facebook.com/techorphic1?mibextid=ZbWKwL"><div className='circle_w mr-3'><img src='/images/facebook.svg' alt=''/></div></Link>
                 {/* <div className='circle_w mr-3'> <img src='/images/linkdin.svg' alt=''/></div> */}
              <div onClick={()=>Navigate("//www.linkedin.com/company/techorphic")}  className='circle_w'> <img src='/images/i.svg' alt=''/></div>
                   
                </div>
            </div>
            <div></div>
            {/* <div className='item_flex2'>
                <div className='item_title pb-2'>Links</div>
                <div className='sub_title'>About Us</div>
                <div className='sub_title'>Services</div>
                <div className='sub_title'>Pricing</div>
                <div className='sub_title'>Projects</div>

             
            </div> */}
            {/* <div className='item_flex3'>
            <div className='item_title pb-2'>Pages</div>
                <div className='sub_title'>Contact Us</div>
                <div className='sub_title'>FAQ</div>
                <div className='sub_title'>Terms of Service</div>
                <div className='sub_title'>Privacy Policy</div>
            </div> */}
            <div className='item_flex4'>
            <div className='item_title pb-2'>Subscribe Newsletter</div>
            <div className='footer_input'>
            <input className='footr_in' type="text" placeholder='Enter your email'/>
            </div>
            <button className='footer_btn'>Subscribe</button>
            </div>
        </div>
        <div className='area_section pb-4'>
            <div className='area_flex'>
                <img className='mr-3' src='/images/flag1.svg.svg' alt=''/>
                <div className=''><div className='subtitle3'>USA :</div>
                <div className='subtitle2'>Techorphic, LLC 900 Lafayette<br></br>St #201,Santa Clara, CA 95050 <br></br> Telephone: +1 (914) 520-0451 <br></br> Email: info@techorphic.com</div>
            </div></div>
                
            <div className='area_flex'>
                <img className='mr-3' src='/images/flag3.svg.svg' alt=''/>
                <div className=''><div className='subtitle3'>PAKISTAN :</div>
                <div className='subtitle2'>Plaza # 15, Commercial Zone,<br></br>Block B, Phase 5, DHA, Lahore.<br></br>Telephone: +923231404885<br></br>Email: info@techorphic.com</div>
            </div></div>
            </div>
            <div className='bottom_footer'>
                <div className='bottom_title'>Copyright © 2022 Techorphic. All rights reserved</div>
            </div>
    </div>
  )
}

export default Footer