import React from 'react'

const DesignOverview = () => {
  return (
    <div className='overview_flex'>
    <div className='overview_flex1'>
        <div className="overview_title pb-1">Overview</div>
        <div className='overview_title2'>Create Exceptional Digital<br></br> Products & Branding with<br></br> Techorphic Developers</div>
        <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
        <div className='overview_title3'>You have a thought, however, couldn't say whether it's<br></br> worth investing in it?? We guide you from an idea to a<br></br> completed product that looks and feels perfect. We reveal<br></br> profound experiences about your clients. Successful<br></br> products are not only attractive to your audience but also<br></br> tailored to the needs of users. We know how to make it.</div>
        <div className='overview_rate'>
            <div className='flex_rate over2'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/pe5.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title '>100 +</div>
                    <div className='kk'>Projects successfully<br></br> delivered since 2017</div>
                </div>
            </div>
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p3.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>70 %</div>
                    <div className='kk'>% higher engagement ratios in<br></br> users of our web solutions</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'>
                <div className='flex_rate'>
                <div className='circle_view text-center pt-2'>
                <img src='/images/pe2.svg' alt=""/>
                </div>
                <div className='pl-4'>
                <div className='view_title'>28 M+</div>
                    <div className='kk'>Million people using our<br></br> apps on a B2B2C model</div>
                </div>
                </div>
                   
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p4.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>30 +</div>
                    <div className='kk'>Experts skilled in design and<br></br> engineering</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'></div>
    </div>
    <div className='overview_flex2'>
        <img src='/images/600.png' alt=''/>
    </div>
</div>
  )
}

export default DesignOverview