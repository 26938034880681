import React from 'react'

const QaServices = () => {
  return (
    <div className='mobileServices'>
    <div className='m_service_title'>Technologies Stacks
<br></br>We Offer</div>
<div className='mobile_Services_flex'>
<div className='image_part'>
    <img src="/images/Group 15030-min.png" alt=''></img>
</div>
<div className='content_part'>

  
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Manual Testing</div>
<div className='dev_content'>"Manual Testing: Manual testing is the spine of the whole QA<br></br> process. It is not possible to guarantee the highest quality of a<br></br> product without manual testing operations. Manual testing, as<br></br> its name implies, is done by a person..</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Automation Testing</div>
<div className='dev_content'>"Automation Testing: Today, when for most businesses, it is<br></br> important to deliver software via lightspeed, automated<br></br> testing is in great demand. The fact is that automated<br></br> testing not only enables significant acceleration of the QA<br></br> process and reduces marketing time, but also ensures high-<br></br>quality products.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Functional Testing</div>
<div className='dev_content'>"Functional Testing: Your code must perform as designed by<br></br> your engineering team and as intended by your marketing<br></br> team. Functional testing looks at what software is supposed<br></br> to do and makes sure it actually does that because<br></br> unexpected or problematic behavior of the software has<br></br> real business implications.</div>

        </div>
    </div>
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Full Cycle Testing</div>
<div className='dev_content1'>"Full Cycle Testing : Assessing the full software cycle means<br></br> the involvement of a QA professional platoon in the early<br></br> stages of its development and culminates in the software<br></br> force phase. Software performance and functionality are<br></br> critical to how your dcustomers respond to your operation or<br></br> software.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Compatibility</div>
<div className='dev_content'>"Compatibility Testing In the digital world of<br></br>web, mobile, and IoT has become incredibly<br></br> complex. New digital technologies will be retailed at an<br></br> unknown rate. The release of every new operating<br></br>system or device requires new QA cycles to<br></br>insure a positive customer experience.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Usability Testing</div>"Usability Testing in the digital world of web, mobile,<br></br> and IoT testing has become incredibly complex. <br></br>New digital technologies will be retailed at an unknown rate.<br></br>The release of every new operating system or device<br></br>requires new Usability Testing cycles to insure a<br></br>positive experience.</div>

{/* <div className='service_card_mobiles_content'>"Usability Testing: In the digital world of web, mobile, and<br></br> IoT, comity testing has come incredibly complex. New<br></br> digital technologies will be retailed at an unknown rate.<br></br>
        </div> */}
    </div>
</div>

</div>
<div  className='together_tag'> <div className='together_tag_title'>Let's work together</div>
<img className='arrowww' src='/images/Path 44027.svg' alt=''/></div>

</div>
  )
}

export default QaServices