import React from 'react'

const Stacks = () => {
  return (
    <div className='stack'> 
        <div className='deve_title'>OUR TECHNOLOGY STACK<br></br> & EXPERTISE
</div>
<div className='slack_icos'>
<div className='fix_ico'><img className='mr_ic' src='/images/ico1.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico2.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico3.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico4.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico5.svg' alt=''/></div>
<div className='fix_ico'><img  src='/images/ico6.svg' alt=''/></div>
    </div>
    <div className='slack_icos'>
<div className='fix_ico'><img className='mr_ic' src='/images/ico7.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico8.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico9.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico13.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico11.svg' alt=''/></div>
<div className='fix_ico'><img className='' src='/images/ico12.svg' alt=''/></div>
    </div>
    <div className='slack_icos'>
<div className='fix_ico'><img className='mr_icc' src='/images/git.png' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico13.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico14.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico15.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico16.svg' alt=''/></div>
<div className='fix_ico'><img className='' src='/images/ico17.svg' alt=''/></div>
    </div>
    <div className='slack_icos'>
        <div className='fix_ico'>
        <img className='mr_ic' src='/images/ico18.svg' alt=''/>
        </div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico19.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico20.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico21.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico22.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_bc' src='/images/ico23.svg' alt=''/></div>

    </div>
    <div className='slack_icos'>
        <div className='fix_ico'><img className='mr_ic' src='/images/ico25.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico26.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico27.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_bc' src='/images/ico28.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico29.svg' alt=''/></div>
<div className='fix_ico next_bo'><img className='' src='/images/ico24.svg' alt=''/></div>
    </div>
    </div>
  )
}

export default Stacks