import React from 'react'

const MobileServices = () => {
  return (
    <div className='mobileServices'>
        <div className='m_service_title'>Mobile App Development<br></br>
Services</div>
<div className='mobile_Services_flex'>
    <div className='image_part'>
        <img src="/images/Group 15036.png" alt=''></img>
    </div>
    <div className='content_part'>
    
      
        <div className=''>
            <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>IOS App</div>
<div className='dev_content'> IOS applications get more cash flow than Android<br></br> applications despite the fact that they have a more modest<br></br> piece of the pie. In 2019 (Q2), Apple clients spent about $<br></br> 25bn on applications and in-application buys.</div>

            </div>
            <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Android App</div>
<div className='dev_content'>Android app development services transform your business<br></br> from local to international by connecting you with your<br></br> customer. As of 2019, Android makes up 74% of all mobile<br></br> apps. By 2020, the total revenue of the Google Play Store<br></br> was estimated at about $40 Billion.</div>

            </div>
            <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>React Native</div>
<div className='dev_content'>A successful mobile app creates using three elements<br></br> intelligently: users, the needs of the user or market, and<br></br> the product itself. React Native Development is ideal for<br></br> those applications that rely closely on the user interface<br></br> due to the fact, with a touch effort, we are able to get the<br></br> UI to work on each. -iOS and Android.</div>

            </div>
        </div>
        <div className=''>
            <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Ionic</div>
<div className='dev_content1'>Ionic is an advanced hybrid and cross-mobile app<br></br> development framework that works on both the feel and<br></br> look of your app and its usability in terms of user<br></br> experience. Modern apps require a modern approach to<br></br> development..</div>

            </div>
            <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Xamarin</div>
<div className='dev_content'>An open-source platform for building modern and<br></br> performant applications for iOS, Android, and Windows. Not<br></br> only that, Xamarin mobile app development is solid and<br></br> fast, as it provides an open-source SDK, Xamarin. Forms,<br></br> Mono run time, fully integrated Visual Studio for Mac and<br></br> Windows, and shared code base for various operating<br></br> systems.</div>

            </div>
            <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Flutter</div>
<div className='service_card_mobiles_content'>Flutter is Google's Mobile App Software Development Kit<br></br> (SDK) intended to build cross-platform, web, and work<br></br> desktop applications. It is a finished climate with a system,<br></br> gadgets, and instruments, which allows you to foster<br></br> portable applications productively. </div>

            </div>
        </div>
    </div>
   
    </div>
    <div  className='together_tag'> <div className='together_tag_title'>Let's work together</div>
    <img className='arrowww' src='/images/Path 44027.svg' alt=''/></div>
   
</div>

  )
}

export default MobileServices