import React from 'react'

const DesignServices = () => {
  return (
    <div className='mobileServices'>
    <div className='m_service_title'>Web And Mobile App Design
    <br></br>Services</div>
<div className='mobile_Services_flex'>
<div className='image_part'>
    <img src="/images/designn.png" alt=''></img>
</div>
<div className='content_part'>

  
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>User Experience Design</div>
<div className='dev_content'>"Each of us uses digital products. We press a button and the<br></br> taxi arrives 5 minutes later, clicks another and the door handle<br></br> opens. The mobile app has become a tool, more commonly used to<br></br> manage and pay than to speak. So effective mobile app design is critical.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>User Interface Design</div>
<div className='dev_content'>"By 2020 there has been a major digital migration, with online<br></br> customers and retailers developing a digital ecosystem to<br></br> make their services and products available anywhere,<br></br> anytime.In any case, bridging the gap between those on the web<br></br>and those not on the web remains a critical issue<br></br>or end clients.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Product Design</div>
<div className='dev_content'>"Effective products not only appeal to your audience but are<br></br> also tailored to the needs of the users. With the right<br></br> design tools, design-based approaches, and a personalized<br></br> approach, you can provide your users with an in-depth user<br></br> experience.</div>

        </div>
    </div>
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Wireframing</div>
<div className='dev_content1'>"Designing a wireframe for your website or app is one of the<br></br> most important steps in the design process. Wireframe<br></br> Design Services is a visual presentation of page layout and<br></br> page layout, which shows the layout of the various<br></br> sections on the page and the relationships between those<br></br> sections.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Prototyping</div>
<div className='dev_content'>"Prototyping: Techorphic Developers provide early-stage<br></br> opportunities to clients to test the functionality of UI/UX<br></br> before creating the final designs. Digital Products Screens<br></br> are filled with colors, animations, and easy-to-read text<br></br> that appeals to its target audience..</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Graphic Designing</div>
<div className='service_card_mobiles_content'>"Graphic Design: Graphic Design makes your craft visible<br></br> and helps you create visual content to execute your<br></br> message efficiently. Graphic design has become an<br></br> essential communication tool to create a collaborative<br></br> design that delivers your message to your audience in<br></br> less than 3 seconds.</div>

        </div>
    </div>
</div>

</div>
<div  className='together_tag'> <div className='together_tag_title'>Let's work together</div>
<img className='arrowww' src='/images/Path 44027.svg' alt=''/></div>

</div>
  )
}

export default DesignServices