import React from 'react'

const DevCards = () => {
  return (
    <div className='AppCard'>
         <div className='m_service_title'>Why Should We Use<br></br>Devops?</div>
       <div className='app_card_flex'>
        <div className='app_card'>
        <img src='/images/transform.3e20067a.svg.svg' alt=''/>
            <div className='app_title'>2X FASTER RELEASE</div>
            <div className='app_title2'>Achieve two-times faster release to your customers.</div>
            <button className='appbtn'>VIEW MORE</button>
        </div>
        <div className='app_card'>
            <img src='/images/admin.999a173a.svg.svg' alt=''/>
            <div className='app_title'>COST REDUCTION</div>
           <div className='app_title2'>Bring your overall server cost<br></br> down.</div> 
           <button className='appbtn'>VIEW MORE</button>
        </div>
        <div className='app_card'>
        <img src='/images/verified.1311b15e.svg.svg' alt=''/>
            <div className='app_title'>IMPROVED SCALABILITY</div>
            <div className='app_title2'>Be able to scale your services dynamically with no service outage.</div>
            <button className='appbtn'>VIEW MORE</button>
        </div>
        <div className='app_card'>
        <img src='/images/verified.1311b15e.svg (1).svg' alt=''/>
            <div className='app_title'>REDUCE REAL-TIME LOAD</div>
            <div className='app_title2'>Save more than 80% in real-time<br></br> load.</div>
            <button className='appbtn'>VIEW MORE</button>
        </div>
        
       </div>
    </div>
  )
}

export default DevCards