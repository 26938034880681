import React from 'react'

const WebDevelopmentServices = () => {
  return (
    <div className='mobileServices'>
    <div className='m_service_title'>Web App Development<br></br>
Services<br></br>
</div>
<div className='mobile_Services_flex'>
<div className='image_part'>
    <img src="/images/Group 15037-min.png" alt=''></img>
</div>
<div className='content_part'>
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>PHP DEVELOPMENT</div>
<div className='dev_content'>PHP is an open-source, as well as a server-side scripting<br></br> language and is a powerful tool for flexible and interactive<br></br> web pages that can empower your website because of its<br></br> unique and extensive features. When we develop and<br></br> maintain a website using PHP, it has many benefits and the<br></br> ability to perform complex tasks.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Python</div>
<div className='dev_content'>The simplicity of Python, combined with its flexibility in a<br></br> wide scope of industry arrangements, has made it one of<br></br> the most generally utilized advancement stages today. The<br></br> main reason Python is so popular is that it is easy to read.<br></br> Besides, with a rich history of 30 years, Python has a full-<br></br>grown and steady local area..</div>
        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Node Js Development</div>
<div className='dev_content'>Node.js allows JavaScript to be used on the server-side<br></br> and is ready for building fast and uncontrolled web<br></br> applications. Node.js is a great language for businesses<br></br> looking for speed in production. At Techorphic Developers,<br></br> We use Node.js advanced development processes based on<br></br> industry-leading practices and years of experience, so you<br></br> should make sure you work with good hands.</div>
        </div>
    </div>
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>JAVA</div>
<div className='dev_content1'>A broadly utilized programming language, Java offers critical<br></br> benefits that might be of some value. It's solid, stage<br></br> autonomous, simple-to-learn, and permits developers to<br></br> construct measured projects with reusable code that can be<br></br> utilized pretty much anyplace According to Oracle, an<br></br> estimated three billion devices run Java, making it the most<br></br> prolific programming language.</div>
        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Angular</div>
<div className='dev_content'>Angular allows you to write web applications on the<br></br> client-side as if you have a smart browser. Allows you to<br></br> use beautiful old HTML (or HAML, Jade, and friends!) As<br></br> your template language it allows you to expand the<br></br> HTML syntax to display parts of your app clearly and<br></br> concisely.</div>
        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>React Js</div>
<div className='service_card_mobiles_content'>Techorphic Developers is a React.js development company<br></br>that uses the most relevant and modern tools to<br></br> create remarkable web applications. All products are<br></br> delivered to meet customer requirements, expectations,<br></br> remain competitive, and are highly sought after. React.js<br></br> is an excellent tool for creating large and complex web<br></br> applications.</div>
        </div>
    </div>
</div>

</div>
<div  className='together_tag'> <div className='together_tag_title'>Let's work together</div>
<img className='arrowww' src='/images/Path 44027.svg' alt=''/></div>

</div>
  )
}

export default WebDevelopmentServices