import React from 'react'

const QaOver = () => {
  return (
    <div className='overview_flex'>
    <div className='overview_flex1'>
        <div className="overview_title pb-1">Overview</div>
        <div className='overview_title2'>Quality Assurance - Secure Your<br></br> Product's Success</div>
        <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
        <div className='overview_title3'>We live in a growing digital world. To be successful in this<br></br> digital world, it is important to provide a seamless and<br></br> easy-to-use solution. We help you bring quality software<br></br> and give your customers the best experience. We<br></br> are the main executives who ensure that your product<br></br> works well and improves the life of your customers. You’ve<br></br> come to the right place!</div>
        <div className='overview_rate'>
            <div className='flex_rate over2'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/pe5.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title '>1000 +</div>
                    <div className='kk'>Test Suites<br></br> Created</div>
                </div>
            </div>
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p3.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>100 %</div>
                    <div className='kk'>Automation Testing<br></br> Engineers</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'>
                <div className='flex_rate'>
                <div className='circle_view text-center pt-2'>
                <img src='/images/pe2.svg' alt=""/>
                </div>
                <div className='pl-4'>
                <div className='view_title'>40</div>
                    <div className='kk'>Technologies &<br></br> Frameworks</div>
                </div>
                </div>
                   
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p4.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>200 +</div>
                    <div className='kk'>Clients Across The<br></br> Globe</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'></div>
    </div>
    <div className='overview_flex2'>
        <img src='/images/Group 15029-min.png' alt=''/>
    </div>
</div>
  )
}

export default QaOver