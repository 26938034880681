import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Hero = () => {
    const data = [
        {
       
          title: "Build Secure Future With",
        title2:"Blockchain",
           title3:"Technology",
           imagee:"/images/hr.svg",
          text: "With solid expertise in blockchain development across various industries, we create winning experience for your business development through secure blockchain advancement.",
          image: "/images/hero.png",
        },
        {
       
            title: "Build Secure Future With",
          title2:"Blockchain",
             title3:"Technology",
             imagee:"/images/hr.svg",
            text: "With solid expertise in blockchain development across various industries, we create winning experience for your business development through secure blockchain advancement.",
            image: "/images/hero.png",
          },
          {
       
            title: "Build Secure Future With",
          title2:"Blockchain",
             title3:"Technology",
             imagee:"/images/hr.svg",
            text: "With solid expertise in blockchain development across various industries, we create winning experience for your business development through secure blockchain advancement.",
         
            image: "/images/hero.png",
          },
  
    ]
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 600 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
        },
      };
  return (
    <div className="about">
  

    <Carousel
      // swipeable={false}
      // draggable={false}
    //   showDots={true}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      // keyBoardControl={true}
    //   customTransition="all .5"
      draggable={true}
      transitionDuration={300}
      containerClass="main_card"
      // customLeftArrow={<CustomLeftArrow />}
      // customRightArrow={<CustomRightArrow />}
      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
    //   deviceType={props.deviceType}
      // dotListClass="custom-dot-list-style"
      //   itemClass="centerFlex"
    >
      {data.map((el) => {
        return (
          <div className="Hero_slider">
          

            <div className="content_box">
              <div class="hero_title">{el.title}<span className='clr ml-1'>{el.title2}</span><br></br>{el.title3}</div>
              
              <img className='low' src={el.imagee} alt="" />
              <div className="rating">
                <div className="hero_text">{el.text}</div>
               
              </div>
            
              </div>
            
              <div className="hero_img">
              <img src={el.image} alt=""></img> 
              </div>
            
          </div>
        );
      })}
    </Carousel>
  </div>
  )
}

export default Hero
