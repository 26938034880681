import React from 'react'

const QaCards = () => {
  return (
    <div className='AppCard'>
    <div className='m_service_title'>Why Should We Use<br></br>Devops?</div>
  <div className='app_card_flex'>
   <div className='app_card'>
   <img src='/images/transform.3e20067a.svg.svg' alt=''/>
       <div className='app_title'>END-TO-END TESTING</div>
       <div className='app_title2'>Our team replicates the user journey and behaviors throughout your app.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
       <img src='/images/admin.999a173a.svg.svg' alt=''/>
       <div className='app_title'>INTEGRATION TESTING</div>
      <div className='app_title2'>We ensure every new module and feature plays nicely with the others.</div> 
      <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
   <img src='/images/verified.1311b15e.svg.svg' alt=''/>
       <div className='app_title'>REGRESSION TESTING</div>
       <div className='app_title2'>We check functionality so that additions don’t negatively affect existing features.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
   <img src='/images/verified.1311b15e.svg (1).svg' alt=''/>
       <div className='app_title'>IMPROVE SECURITY</div>
       <div className='app_title2'>Enable adding features & functionalities to your web solutions that customize app experience for users.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   
  </div>
</div>
  )
}

export default QaCards