import React from 'react'

const CompanyOver = () => {
  return (
    <>
    <div className='overview_flex'>
    <div className='overview_flex1'>
        
        <div className='overview_title2'>Your Innovative Solution<br></br> Partner In Digital World</div>
        <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
        <div className='overview_title3'>Our experience has taught us one thing: user experience is<br></br> the key component of any successful app. Our team uses<br></br> native and hybrid technologies for iOS and Android to<br></br> provide the best performance and experience. Mobile<br></br> operation systems change rapidly, to help your product<br></br> take advantage of new features and remain user-friendly,<br></br> we offer long-term support and upgrade packages for all<br></br> iOS and Android app design.</div>
        <div className='overview_rate'>
            <div className='flex_rate over2'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/pe5.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title '>30+</div>
                    <div className='kk'>Experts across a range of<br></br> specializations</div>
                </div>
            </div>
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p3.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>03</div>
                    <div className='kk'>So far we have offices across<br></br> 03 countries</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'>
                <div className='flex_rate'>
                <div className='circle_view text-center pt-2'>
                <img src='/images/pe2.svg' alt=""/>
                </div>
                <div className='pl-4'>
                <div className='view_title'>04Y</div>
                    <div className='kk'>We have been active in<br></br> business</div>
                </div>
                </div>
        </div>
        <div className='overview_rate'></div>
    </div>
    <div className='overview_flex2'>
       <div className='pb-4'>Headquartered in New York City And Lahore Pakistan,<br></br> Techorphic Developers is an international agency providing<br></br> website and mobile digital solutions for businesses worldwide.</div>
       <div>Techorphic Developers has worked with organizations of all sizes<br></br> to create solutions to meet their business needs. Our agency is<br></br> comprised of flexible teams that can be scaled up until or down to<br></br> meet the demands of any business for any project at any time.<br></br> Collaborating with our clients is the first step towards designing a<br></br> quality solution. Our company is dedicated to the Techorphic<br></br> Developers goal: a better tomorrow for your business.</div>
    </div>
 
</div>
   <div className='company_cards'>
   <div className='company_Card1'>
       <div className='company_title'>What We Do</div>
       <div className='circle_view33'><img src='/images/Service.png' alt=''/></div>
       <div className='company_content'>Achieve your business goals and create digital solutions that people love with our development approach</div>
       <button className='company_btn'>View Our Expertise</button>
   </div>

   <div className='company_Card2'>
       <div className='company_title'>Why Choose Us</div>
       <div className='circle_view33'><img src='/images/Choose.png' alt=''/></div>
       <div className='company_content'>We have built a great team of engineers that we use to deliver solutions that meet customer needs.</div>
       <button className='company_btn'>Find Out More</button>
   </div>
   </div>

</>
  )
}

export default CompanyOver