import React from 'react'

const DevOver = () => {
  return (
    <div className='overview_flex'>
    <div className='overview_flex1'>
        <div className="overview_title pb-1">Overview</div>
        <div className='overview_title2'>Structure your Development &<br></br> Deployment Process through<br></br> Modern DevOps Practices</div>
        <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
        <div className='overview_title3'>Move to Continuous Delivery, you see better tools, robust<br></br> systems, and faster marketing time. Our DevOps services<br></br> help drive the culture of "Everything as a Code", creating<br></br> harmony in cloud performance.If your organization aims<br></br> for fast and seamless delivery of products/services in and<br></br> out of the cloud, Techorphic can help you apply<br></br> measurable results through our services.</div>
        <div className='overview_rate'>
            <div className='flex_rate over2'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/pe5.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title '>50 +</div>
                    <div className='kk'>Projects successfully<br></br> delivered since 2017</div>
                </div>
            </div>
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p3.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>40 %</div>
                    <div className='kk'>higher engagement ratios in<br></br> users of our web solutions</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'>
                <div className='flex_rate'>
                <div className='circle_view text-center pt-2'>
                <img src='/images/pe2.svg' alt=""/>
                </div>
                <div className='pl-4'>
                <div className='view_title'>1</div>
                    <div className='kk'>Million people using our<br></br> apps on a B2B2C model</div>
                </div>
                </div>
                   
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p4.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>100 +</div>
                    <div className='kk'>Experts skilled in design and<br></br> engineering</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'></div>
    </div>
    <div className='overview_flex2'>
        <img src='/images/Group 15043-min.png' alt=''/>
    </div>
</div>
  )
}

export default DevOver