import React from 'react'

const Overview = () => {
  return (
    <div className='overview_flex'>
        <div className='overview_flex1'>
            <div className="overview_title pb-1">Overview</div>
            <div className='overview_title2'>iOS, Android, & Hybrid Mobile<br></br> App Development</div>
            <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
            <div className='overview_title3'>Our experience has taught us one thing: user experience is<br></br> the key component of any successful app. Our team uses<br></br> native and hybrid technologies for iOS and Android to<br></br> provide the best performance and experience. Mobile<br></br> operation systems change rapidly. to help your product<br></br> take advantage of new features and remain user-friendly,<br></br> we offer long-term support and upgrade packages for all<br></br> iOS and Android app design.</div>
            <div className='overview_rate'>
                <div className='flex_rate over2'>
                <div className='circle_view text-center pt-2'>
                    <img src='/images/pe5.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                        <div className='view_title '>500 +</div>
                        <div className='kk'>Projects successfully<br></br> delivered since 2017</div>
                    </div>
                </div>
                <div className='flex_rate pl-4'>
                <div className='circle_view text-center pt-2'>
                    <img src='/images/p3.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                        <div className='view_title'>70%</div>
                        <div className='kk'>higher engagement ratios in<br></br> users of our web solutions</div>
                    </div>
                </div>
            </div>
            <div className='overview_rate'>
                    <div className='flex_rate'>
                    <div className='circle_view text-center pt-2'>
                    <img src='/images/pe2.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                    <div className='view_title'>28 M+</div>
                        <div className='kk'>Million people using our<br></br> apps on a B2B2C model</div>
                    </div>
                    </div>
                       
                <div className='flex_rate pl-4'>
                <div className='circle_view text-center pt-2'>
                    <img src='/images/p4.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                        <div className='view_title'>30 +</div>
                        <div className='kk'>Experts skilled in design and<br></br> engineering</div>
                    </div>
                </div>
            </div>
            <div className='overview_rate'></div>
        </div>
        <div className='overview_flex2'>
            <img src='/images/Group 15028 (1).png' alt=''/>
        </div>
    </div>
  )
}

export default Overview