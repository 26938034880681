import React from "react";
import { Carousel } from "3d-react-carousal";
export default function Carosuls() {
  let slides = [
   <div className="Carosel_div">
    <img className="rate" src="/images/rating.png" alt=""></img> 
    <p className="font_rate">Danyal and his Team is very professional, responsive, methodical and always willing to go the extra mile to assist in a client's request. I would certainly recommend him to other companies looking for similar services.</p>
    <div className="client_title4">Dane V.</div>
    <img className="qute_rate" src="/images/copp.svg" alt=""/></div>,
      <div className="Carosel_div">
      <img className="rate" src="/images/rating.png" alt=""></img> 
      <p className="font_rate">Techorphic has done a very good job working with me in this project.The dialog has been flawless and raised questions has been addressed right away. Very professional!</p>
      <div className="client_title4">John S.</div>
      <img className="qute_rate" src="/images/copp.svg" alt=""/></div>,
        <div className="Carosel_div">
        <img className="rate" src="/images/rating.png" alt=""></img> 
        <p className="font_rate">Danyal and his team have been exceptional with building our mobile app for iOS and Android. He listens carefully to to our requirements and follows the design briefs and UI flow.Technically he is exceptional.</p>
        <div className="client_title4">Mark N.</div>
        <img className="qute_rate" src="/images/copp.svg" alt=""/></div>,
         <div className="Carosel_div">
         <img className="rate" src="/images/rating.png" alt=""></img> 
         <p className="font_rate">Danyal is amazing, I had something come up mid development and I had to end the contract and I am waiting to see how this goes but so far Danyal has been amazing. I'm most likely going to work with him again in the near future.</p>
         <div className="client_title4">Hisham M.</div>
         <img className="qute_rate" src="/images/copp.svg" alt=""/></div>,
  ];
  return (
    <div  className="my-5 mb-4 height_div">
      <Carousel slides={slides} />
    </div>
  );
}
