import React from 'react'

const WebOwerview = () => {
  return (
    <div className='overview_flex'>
        <div className='overview_flex1'>
            <div className="overview_title pb-1">Overview</div>
            <div className='overview_title2'>Grow Your Business With<br></br> Bespoke Web Development<br></br> Solutions</div>
            <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
            <div className='overview_title3'>We understand that successful solutions are only possible<br></br> through collaboration. We listen to our clients' ideas and<br></br> needs, then build a solution framework based on their<br></br> feedback and our team’s extensive knowledge of all things<br></br> design and programming. We work to create solutions that<br></br> meet the objectives of the business and provide a<br></br> fantastic experience for your users.</div>
            <div className='overview_rate'>
                <div className='flex_rate over2'>
                <div className='circle_view text-center pt-2'>
                    <img src='/images/pe5.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                        <div className='view_title '>500 +</div>
                        <div className='kk'>Projects successfully<br></br> delivered since 2017</div>
                    </div>
                </div>
                <div className='flex_rate pl-4'>
                <div className='circle_view text-center pt-2'>
                    <img src='/images/p3.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                        <div className='view_title'>70%</div>
                        <div className='kk'>higher engagement ratios in<br></br> users of our web solutions</div>
                    </div>
                </div>
            </div>
            <div className='overview_rate'>
                    <div className='flex_rate'>
                    <div className='circle_view text-center pt-2'>
                    <img src='/images/pe2.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                    <div className='view_title'>28 M+</div>
                        <div className='kk'>Million people using our<br></br> apps on a B2B2C model</div>
                    </div>
                    </div>
                       
                <div className='flex_rate pl-4'>
                <div className='circle_view text-center pt-2'>
                    <img src='/images/p4.svg' alt=""/>
                    </div>
                    <div className='pl-4'>
                        <div className='view_title'>30 +</div>
                        <div className='kk'>Experts skilled in design and<br></br> engineering</div>
                    </div>
                </div>
            </div>
            <div className='overview_rate'></div>
        </div>
        <div className='overview_flex2'>
            <img src='/images/webb.png' alt=''/>
        </div>
    </div>
  )
}

export default WebOwerview