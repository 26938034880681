import React from 'react'
import { useNavigate } from 'react-router-dom';
const DeveServices = () => {
  const Navigate = useNavigate ();
  return (
    <div className='deve_services'>
        <div className='deve_title'>Our Bespoke Software<br></br> Development Services</div>
        <div className='deve_content'>We understand that successful solutions are only possible through<br></br> collaboration. We listen to our client’s ideas and needs, then build a solution<br></br> framework based on their feedback and our team’s extensive knowledge of<br></br> all things design and programming.</div>
        <div className='services_flex1'>
        
            <div onClick={()=>Navigate("/web-development")} className='service_card'>
<img src='/images/material-symbols_laptop-mac-outline.svg' alt=''/>
<div className='service_card_title'>WEB DEVELOPMENT</div>
<div className='dev_content'>Today's modish solution is an amalgamation of multiple interconnected technologies. Everything we create is purposefully designed to your specifications.</div>
<button className='view_btn'>View more</button>
            </div>
            <div onClick={()=>Navigate("/mobile-development")} className='service_card'>
<img src='/images/tabler_device-mobile-charging.svg' alt=''/>
<div className='service_card_title'>APP DEVELOPMENT</div>
<div className='dev_content'>We build digitally transform any business into an app that can be monetized through purchasing a download. Our designed App is full of security, and usability in mind.</div>
<button className='view_btn'>View more</button>
            </div>
            <div onClick={()=>Navigate("/blockchain-development")} className='service_card'>
<img src='/images/eos-icons_blockchain.svg' alt=''/>
<div className='service_card_title'>BLOCKCHAIN DEVELOPMENT</div>
<div className='dev_content'>Blockchains dominance the future. To develop blockchain-based projects, we offer fully scalable, attract high-class specialists, trendsetters, and visionaries in the blockchain field.</div>
<button className='view_btn'>View more</button>
            </div>
        </div>
        <div className='services_flex2'>
            <div onClick={()=>Navigate("/devops")} className='service_card'>
<img src='/images/tabler_device-mobile-charging (1).svg' alt=''/>
<div className='service_card_title'>CLOUD SERVICES & DEVOPS</div>
<div className='dev_content'>Kick-start your transition journey to Cloud-based. We empower businesses from strategic to creating secure, uncluttered, highly efficient cloud solutions.</div>
<button className='view_btn'>View more</button>
            </div>
            <div onClick={()=>Navigate("/qa")} className='service_card'>
<img src='/images/tabler_device-mobile-charging.svg' alt=''/>
<div className='service_card_title'>QUALITY ASSURANCE</div>
<div className='dev_content'>Techorphic Developers, take full commitment to the success of the application. QA automation reduces costs and provides confidence in 100% quality solutions.</div>
<button className='view_btn'>View more</button>
            </div>
            <div  onClick={()=>Navigate("/designing")} className='service_card'>
<img src='/images/eos-icons_blockchain.svg' alt=''/>
<div className='service_card_title'>PRODUCT DESIGN</div>
<div className='dev_content'>By working with you in pre-determined checkpoints, we turn ideas into solid visual presentations to promote product ideas, taking feedback from participants along the way.</div>
<button className='view_btn'>View more</button>
            </div>
        </div>
    </div>
  )
}

export default DeveServices