import React from 'react'

const WhatWe = () => {
  return (
    <div className='weare'>
        <div className='weare_flex1'>
            <div className='text_2'>
            <div className='company_title'>What We Do</div>
            <div className='content_valu'>Techorphic Developers is an international agency that designs<br></br> and builds software applications and bespoke digital solutions for businesses world-wide. From<br></br> local businesses to international corporations, Techorphic<br></br> Developers has worked with organizations of all sizes to create<br></br> solutions to meet their business needs. Our agency is comprised of<br></br> flexible teams that can be scaled up or down to meet the demands<br></br> of any business for any project at any time. Our vision is to<br></br> constantly improve mobile apps and the web and grow as a great<br></br> mobile app and web design and development company.</div>
            </div>
            <div className='what_we' ><img src='/images/what-we-do-img.556af7d5.png.png' alt=''/></div>
             </div>
        <div className='weare_flex2'>
        <div className='what_we'><img  src='/images/what-we-believe-img.211b788e.png.png' alt=''/></div>
        <div className='text_2'>
            <div className='company_title'>What We Believe</div>
            <div className='content_valu'>We work to create solutions that meet the objectives of the business<br></br> and provide a fantastic experience for your users. By combining both<br></br> programming experts with marketing consultants, the Techorphic<br></br> Developers team offers its clients a unique combination of skills and<br></br> abilities. Our virtual agency’s creativity, innovation, and skills<br></br> represent the most promising talent from around the world. Our staff<br></br> is dedicated to the Techorphic Developers goal: a better tomorrow<br></br> for your business. We also conduct workshops and rehabilitation<br></br>programs for our employees to support their continuous professional <br></br>and personal growth.</div>
            </div>
        </div>
    </div>
  )
}

export default WhatWe