// import './App.css';
import './Min.css'
import './services.css';
import { Route, Routes } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import Expertise from './pages/Expertise';
import MobileDevelopment from './pages/MobileDevelopment';
import WebsiteDeve from './pages/WebsiteDeve';
import BlockChain from './pages/BlockChain';
import Devops from './pages/Devops';
import QA from './pages/QA';
import Design from './pages/Design';
import DigitalServices from './pages/DigitalServices';
import Company from './pages/Company';
import ContactUss from './components/ContactUss';
function App() {
  return (
    <div className="App">
       <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path='/experise' element={<Expertise/>}/>
        <Route path='/mobile-development' element={<MobileDevelopment/>}/>
        <Route path='/web-development' element={<WebsiteDeve/>}/>
        <Route path='/blockchain-development' element={<BlockChain/>}/>
        <Route path='/devops' element={<Devops/>}/>
        <Route path='/qa' element={<QA/>}/>
        <Route path='/designing' element={<Design/>}/>
        <Route path='/digital-services' element={<DigitalServices/>}/>
        <Route path='/company' element={<Company/>}/>
        <Route path='/contact-us' element={<ContactUss/>}/>
      </Routes>
    </div>
  );
}

export default App;
