import React from 'react'

const Clients = () => {
  return (
    <div>
       <div className='client_ico'>
        <div className='client_title'>Clients and</div>
        <div className='client_title2'>PARTNERS</div>
    <div className='techn_flex1 pb-4'>
        <div className='text-center'>
        <img src='/images/telenor.png.svg' alt=''/>
   
        </div>
       <div className='text-center pl-4 padding_b'>
       <img className='left_border imgfix' src='/images/wix.png.svg' alt=''/>
     
       </div>
       <div className='text-center pl-4 padding_b'>
       <img className='left_border imgfix' src='/images/paypal.png.svg' alt=''/>
       
       </div>
       <div className='text-center pl-4 padding_b'>
       <img className='left_border imgfix' src='/images/maker&son.png.svg' alt=''/>
     
       </div>  
       <div className='techn_flex1'></div>

    </div>
    <div className='techn_flex2'>
        <div className='text-center'>
        <img src='images/phl.png.svg' alt=''/>
    
        </div>
       <div className='text-center pl-4 padding_b'>
       <img className='left_border imgfix' src='/images/kerusso.png.svg' alt=''/>
    
       </div>
       <div className='text-center pl-4 padding_b'>
       <img className='left_border imgfix' src='/images/apteo.png.svg' alt=''/>
     
       </div>
       <div className='text-center pl-4 padding_b'>
       <img className='left_border imgfix' src='/images/satya.png.svg' alt=''/>
    
       </div>
    </div>
 </div>
    </div>
  )
}

export default Clients
