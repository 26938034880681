import React,{useState} from 'react'
import "../Navbar.css";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const Navbar = () => {
  const Navigate = useNavigate ();
       const [isOpen, setIsOpen] = useState(false);
       const [toggleState, setToggleState] = useState(1);
       const toggleTab = (index) => {
        setToggleState(index);
      };
  
  return (
    <div>
        <div className="main_nav">
        <div className="Navbar">
          <Link to="/">
            <img className="" src="/images/logo.svg" alt="" />
          </Link>
          <div className={`nav-items items ${isOpen && "open"}`}>
          <div class="dropdown">
            <Link to="/"><button class="dropbtn">Home</button></Link>
</div>
<div class="dropdown">
  <button onClick={()=>Navigate("/company")} class="dropbtn">Company</button>

</div>
            <div class="dropdown">
             
              <button className={toggleState === 1 ? "dropbtn" : "activebtn"} onClick={() => toggleTab(1)} >Expertise</button>
            
  <img src='/images/dropsign.svg' alt=''/>
  <div class="dropdown-content">
  <Link className='drop_title' to="/mobile-development">App development</Link>
  <Link className='drop_title' to="/web-development">Web development</Link>
  <Link className='drop_title' to="/blockchain-development">Blockchain development</Link>
  <Link className='drop_title' to="/devops">Cloud services & Devops</Link>
  <Link className='drop_title' to="/qa">Quality Assurance</Link>
  <Link className='drop_title' to="/designing">Product Design</Link>
  </div>
</div>

 
            <div class="dropdown">
  <button  onClick={()=>Navigate("/digital-services")} class="dropbtn">Digital Services</button>
</div>
       <div className='search_bar'>
        <img src='/images/search.svg' alt=''/>
       </div>
       <div>  <Link to="Https://wa.me/3231404885"><button
              className="creat_accout1"
            >
     Hire Tech Staff
            </button></Link></div>
           <div>
            <Link to="/contact-us">
           <button
              className="creat_accout"
            >
         Free Estimation
            </button></Link>
           </div>
         
          </div>
          <div
            className={`nav-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
