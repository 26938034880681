import React from 'react'

const BannerImage = () => {
  return (
<div className='value'>
    <div className='choose_title text-center mb-2'>Core values</div>
         <div className='choose_title2 text-center pb-2'>Do, Belives & Values</div>
         <div className='value_content text-center'>We connect with our clients, our communities, and our company. It makes us<br></br> different. It makes us better.</div>
         <img className='banner_img' style={{width:"100%"}} src="/images/ko.png" alt=''/></div>
  )
}

export default BannerImage