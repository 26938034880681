import React from 'react'

const Techno = () => {
  return (
   <>         <div className='experties_section pb-4'>
   <h2 className='pb-3'>OUR CORE TECHNOLOGIES</h2>
   <div className='pb-4 tech_heading'>Techorphic works under various modern technologies for effective, scalable, and future-proof custom software development.</div>
     </div>
 <div className='techno_ico'>
    <div className='techn_flex1 pb-4'>
        <div className='text-center pb-3'>
        <img className='ico_size' src='/techno_images/Java logo.svg' alt=''/>
        <div className='title_tec1'>Java</div>
        </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/android-plain logo.svg' alt=''/>
       <div className='title_tec'>Android</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/Apple logo.svg' alt=''/>
       <div className='title_tec'>Ios</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/images/vue.png.svg' alt=''/>
       <div className='title_tec'>Vue</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/Frame 3890.svg' alt=''/>
       <div className='title_tec'>Laravel</div>
       </div>
       <div className='text-center pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/Frame 3893.svg' alt=''/>
       <div className='title_tec'>Blockchain</div>
       </div>
       <div className='text-center pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/cloud logo.svg' alt=''/>
       <div className='title_tec'>Cloud Computing</div>
       </div>  
      
       <div className='techn_flex1'></div>

    </div>
    <div className='techn_flex2'>
        <div className='text-center pb-3 pad_iss'>
        <img className='ico_size' src='/techno_images/libre-brand-codeignitor logo.svg' alt=''/>
        <div className='title_tec1'>Codeignitor</div>
        </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/Django logo.svg' alt=''/>
       <div className='title_tec'>Django</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/php logo.svg' alt=''/>
       <div className='title_tec'>Php</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/nodejs logo.svg' alt=''/>
       <div className='title_tec'>Node Js</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/python logo.svg' alt=''/>
       <div className='title_tec'>Python</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/react.svg' alt=''/>
       <div className='title_tec'>React Native</div>
       </div>
       <div className='text-center pl-4 pb-3 pad_iss'>
       <img className='left_border ico_size' src='/techno_images/libre-brand-ror logo.svg' alt=''/>
       <div className='title_tec'>RoR</div>
       </div>  
      

    </div>
 </div>
 </>

  )
}

export default Techno
