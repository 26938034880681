import React from 'react'

const BlockOver = () => {
  return (
    <div className='overview_flex'>
    <div className='overview_flex1'>
        <div className="overview_title pb-1">Overview</div>
        <div className='overview_title2 line_height'>Transform Digital Future with<br></br> Techorphic Blockchain<br></br> Development Service!</div>
        <div className='text-center'><img className='mb-4 mt-4' src='/images/Line 7.svg' alt=''></img></div>
        <div className='overview_title3'>Explore the world of secure, compact, and decentralized<br></br> applications with our blockchain end-to-end services. We<br></br> offer blockchain development services and provide<br></br> solutions to companies worldwide. We assist businesses<br></br> to leverage the power of blockchain technology and<br></br> solutions to gain transparency at the next level and make<br></br> their way into their business processes.</div>
        <div className='overview_rate'>
            <div className='flex_rate over2'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/pe5.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title '>500 +</div>
                    <div className='kk'>Projects successfully<br></br> delivered since 2017</div>
                </div>
            </div>
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p3.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>70%</div>
                    <div className='kk'>higher engagement ratios in<br></br> users of our web solutions</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'>
                <div className='flex_rate'>
                <div className='circle_view text-center pt-2'>
                <img src='/images/pe2.svg' alt=""/>
                </div>
                <div className='pl-4'>
                <div className='view_title'>28 M+</div>
                    <div className='kk'>Million people using our<br></br> apps on a B2B2C model</div>
                </div>
                </div>
                   
            <div className='flex_rate pl-4'>
            <div className='circle_view text-center pt-2'>
                <img src='/images/p4.svg' alt=""/>
                </div>
                <div className='pl-4'>
                    <div className='view_title'>30 +</div>
                    <div className='kk'>Experts skilled in design and<br></br> engineering</div>
                </div>
            </div>
        </div>
        <div className='overview_rate'></div>
    </div>
    <div className='overview_flex2'>
        <img src='/images/Group 15039-min.png' alt=''/>
    </div>
</div>
  )
}

export default BlockOver