import React from 'react'

const Office = () => {
  return (
    <div className='office_space'>
        <div className='office_title'>OUR OFFICES AND<br></br>
TEAM MEMBERS</div>
    </div>
  )
}

export default Office