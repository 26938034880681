import React from 'react'
import BannerImage from '../components/Company/BannerImage'
import ChooseCompany from '../components/Company/ChooseCompany'
import CompanyOver from '../components/Company/CompanyOver'
import Joining from '../components/Company/Joining'
// import OurTeam from '../components/Company/OurTeam'
import OurValue from '../components/Company/OurValue'
import WhatWe from '../components/Company/WhatWe'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
const Company = () => {
  return (
    <div>
    <div className='top_header2'>
   <div className='top_flex1'>
   <div className='top_section1'>
   <div className='top_ico'>
       <img src='/images/icon.svg' alt=''/>
   </div>
   <div className='top_content'>
       <div className='hdr_clr'>Contact us</div>
       <div className='hdr_clr'>+92 090909090</div>
   </div>
 </div>
 <div className='top_section2'>
   <div className='top_ico'>
   <img src='/images/second.svg' alt=''/>
   </div>
   <div className='top_content'>
   <div className='hdr_clr'>Have a question?</div>
       <div className='hdr_clr'>info@techorphic.com</div>
   </div>
 </div>
   </div>
   <div className='top_flex2'>
   {/* <div className='text-center mr-4'>
   <div className='top_ico'>
       <img src='/images/Survey.svg' alt=''/>
   </div>
       <div className='hdr_clr'>Case Study</div>
 </div> */}
 {/* <div className='text-center mr-4'>
   <div className='top_ico'>
       <img src='/images/Frame.svg' alt=''/>
   </div>
       <div className='hdr_clr'>Careers</div>
 </div> */}
 <div className='text-center'>
   <div className='top_ico'>
       <img src='/images/contactus.svg' alt=''/>
   </div>
       <div className='hdr_clr'>Contact Us</div>
 </div>
   </div>


</div>
   <Navbar/>
   <div className="about">
     <div className="hero2">
       <div className="">
         <div className='first_title'>Home /<span className='clr pl-1'>Digital Services</span></div>
         <div class="hero2_title ">Who<span className='clr pl-2'>We Are ?</span></div>
         
       <img className='lin' src='/images/hr.svg' alt=''/>
         <div className="rating pt-1">
           <div className="hero_text">From startup to enterprise, the entire range of<br></br> engineering offerings your business needs</div>
          
         </div>
         </div>
         <div className="hero_img">
         <img src="" alt=""></img> 
         </div>   
     </div>
</div>
<CompanyOver/>
<ChooseCompany/>
<BannerImage/>
<WhatWe/>
<OurValue/>
<div className='stack'>
  <div className='choose_title text-center pb-3'>TECHNOLOGIES</div> 
        <div className='deve_title'>Languages, Tools & Frameworks
</div>
<div className='slack_icos'>
<div className='fix_ico'><img className='mr_ic' src='/images/ico1.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico2.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico3.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico4.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico5.svg' alt=''/></div>
<div className='fix_ico'><img  src='/images/ico6.svg' alt=''/></div>
    </div>
    <div className='slack_icos'>
<div className='fix_ico'><img className='mr_ic' src='/images/ico7.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico8.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico9.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico13.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico11.svg' alt=''/></div>
<div className='fix_ico'><img className='' src='/images/ico12.svg' alt=''/></div>
    </div>
    <div className='slack_icos'>
<div className='fix_ico'><img className='mr_icc' src='/images/git.png' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico13.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico14.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico15.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico16.svg' alt=''/></div>
<div className='fix_ico'><img className='' src='/images/ico17.svg' alt=''/></div>
    </div>
    <div className='slack_icos'>
        <div className='fix_ico'>
        <img className='mr_ic' src='/images/ico18.svg' alt=''/>
        </div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico19.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico20.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico21.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico22.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_bc' src='/images/ico23.svg' alt=''/></div>

    </div>
    <div className='slack_icos'>
        <div className='fix_ico'><img className='mr_ic' src='/images/ico25.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico26.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico27.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_bc' src='/images/ico28.svg' alt=''/></div>
<div className='fix_ico'><img className='mr_ic' src='/images/ico29.svg' alt=''/></div>
<div className='fix_ico next_bo'><img className='' src='/images/ico24.svg' alt=''/></div>
    </div>
    </div>
<Joining/>
{/* <OurTeam/>   */}
<ContactUs/>
<Footer/>
   </div>
  )
}

export default Company