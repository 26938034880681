import React from 'react'
import { Link } from 'react-router-dom'
const Quote = () => {
  return (
    <div className='quote'>
      <div className='quote_content'>
        <img src='/images/div.svg' alt=''/>
        <p className='quote_content'>We develop individualized software<br></br> solutions that are unique and beyond<br></br> the standard approach.</p>
        <Link to="/contact-us"><button className='quotebtn'>GET A QUOTE</button></Link>
      </div>
      <div className='quote_images'>
        <img src='/images/qout.png' alt=''/>
      </div>
    </div>
  )
}

export default Quote
