import React from 'react'

const OurValue = () => {
  return (
    <div className='ourvalue'>
        <div className='ourvalue_title text-center'>Our Values</div>
        <div className='ourvalue_content text-center'>We wаnt tо орerаte in а wаy оur emрlоyees саn be рrоud оf. Оur vаlues serve аs<br></br> а соmраss in evаluаting орроrtunities аnd guiding оur deсisiоn-mаking.</div>
        <div className='value_card'>
            <div className='ourvalue_cards'>
                <div className='ourvalue_card'>
                <div className='circles_white'><img src='/images/e4.svg' alt=''/></div>
                    <div className='overvalue_title'>Commitment</div>
                    <div className='overvalue_content'>Be accountable, work together as a team<br></br> and communicate clearly.</div>
                </div>
                <div className='ourvalue_card'>
                <div className='circles_white'><img src='/images/e3.svg' alt=''/></div>
                    <div className='overvalue_title'>Innovation</div>
                    <div className='overvalue_content'>Think outside the box, challenge<br></br> constructively and act before others do.</div>
                </div>
            </div>
            <div className='ourvalue_cards'>
            <div className='ourvalue_card'>
                <div className='circles_white'><img src='/images/e2.svg' alt=''/></div>
                    <div className='overvalue_title'>Engage</div>
                    <div className='overvalue_content'>Creating a positive place to work and<br></br> supporting ethical initiatives.</div>
                </div>
                <div className='ourvalue_card'>
                <div className='circles_white'><img src='/images/e1.svg' alt=''/></div>
                    <div className='overvalue_title'>Excellence</div>
                    <div className='overvalue_content'>Always deliver exceptional quality,<br></br> accomplish and improve.</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurValue