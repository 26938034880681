import React from 'react'

const Joining = () => {
  return (
    <div className='Joining'>
        <div className='pb-4 choose_title text-center'>JOINING POCCESS</div>
        <div className='ourvalue_title text-center'>How To Get Into<br></br> Techorphic Developers</div>
        <div className='join_icoflex mb-4'>
            <div className='circle_blu'><img src='/images/apply1.png' alt=''/></div>
            <div className='circle_bl'><img src='/images/apply2.png' alt=''/></div>
            <div className='circle_bl'><img src='/images/apply3.png' alt=''/></div>
            <div className='circle_bl'><img src='/images/apply4.png' alt=''/></div>
        </div>
        <div className='join_icoflex mt-4'>
            <div style={{width:"200px"}} className='choose_title mt-4'>Apply</div>
            <div className='mt-4 apply_text'>Searching for a job? Then just scroll through the open vacancies below and observe for positions that align with your specific skillset. If you'll be successful in finding an open vacancy at our website according to your skillset. Make sure to choose the right category and go to the form to apply, fill out proper details, and mention details properly.</div>
        </div>
        <div className='join_icoflex'>
            <div style={{width:"200px"}}  className='choose_title mt-4'>Review</div>
            <div className='mt-4 apply_text'>When you will apply for the open vacancy according to your skillset, now it's time to review the candidates' applications. Our expert staff reviews your request. They will check your resume and find what is best for you. All the candidates who will fulfill the requirements will be shortlisted for further processing.</div>
        </div>
        <div className='join_icoflex'>
            <div style={{width:"200px"}}  className='choose_title mt-4'>Interview</div>
            <div className='mt-4 apply_text'>After shortlisting the ideal candidates, our Board selects those for interviews.usually consist of: a recruiter’s phone call; a hiring manager's department has its terms, but usually rounds the recruiter’s phone screen, the hiring manager’s interview, and a combination of technical, panel and team presentations.</div>
        </div>
        <div className='join_icoflex'>
            <div style={{width:"200px"}}  className='choose_title mt-4'>Onboarding</div>
            <div className='mt-4 apply_text'>Once you have received the message “Welcome Aboard”, our onboarding experience will polish and plan your professional journey with Techorphic Developers. We provide our employees the opportunity to grow, polish their skills and touch new heights. We facilitate them with different salaries, bonuses, and medical perks.</div>
        </div>
    </div>
  )
}

export default Joining