import React from 'react'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import MobileServices from '../components/MobileAppComponents/MobileServices'
import Overview from '../components/MobileAppComponents/Overview'
import Navbar from '../components/Navbar'
import AppCard from '../components/MobileAppComponents/AppCard'
const MobileDevelopment = () => {
  return (
    <div>
         <div className='top_header2'>
        <div className='top_flex1'>
        <div className='top_section1'>
        <div className='top_ico'>
            <img src='/images/icon.svg' alt=''/>
        </div>
        <div className='top_content'>
            <div className='hdr_clr'>Contact us</div>
            <div className='hdr_clr'>+92 090909090</div>
        </div>
      </div>
      <div className='top_section2'>
        <div className='top_ico'>
        <img src='/images/second.svg' alt=''/>
        </div>
        <div className='top_content'>
        <div className='hdr_clr'>Have a question?</div>
            <div className='hdr_clr'>info@techorphic.com</div>
        </div>
      </div>
        </div>
        <div className='top_flex2'>
        {/* <div className='text-center mr-4'>
        <div className='top_ico'>
            <img src='/images/Survey.svg' alt=''/>
        </div>
            <div className='hdr_clr'>Case Study</div>
      </div> */}
      {/* <div className='text-center mr-4'>
        <div className='top_ico'>
            <img src='/images/Frame.svg' alt=''/>
        </div>
            <div className='hdr_clr'>Careers</div>
      </div> */}
      <div className='text-center'>
        <div className='top_ico'>
            <img src='/images/contactus.svg' alt=''/>
        </div>
            <div className='hdr_clr'>Contact Us</div>
      </div>
        </div>
   
    
    </div>
        <Navbar/>
        <div className="about">
          <div className="hero2">
          

            <div className="">
              <div className='first_title'>Home / Expertise /<span className='clr'>App Development</span></div>
              <div class="hero2_title">Full-service App design<br></br><span className='hero2_title pr-2'>and</span><span className='clr'>Development</span></div>
              
            <img className='lin' src='/images/hr.svg' alt=''/>
              <div className="rating pt-1">
                <div className="hero_text">We build user-friendly, scalable, secure, and reliable web<br></br> design and development solutions that meet your business<br></br> requirements.</div>
               
              </div>
              </div>
              <div className="hero_img">
              <img src="" alt=""></img> 
              </div>
            
          </div>
    

  </div>
  <Overview/>
  <MobileServices/>
<AppCard/>

  <ContactUs/>
  <Footer/>
        </div>
  )
}

export default MobileDevelopment