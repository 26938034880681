import React from 'react'

const WebCards = () => {
  return (
    <div className='AppCard'>
    <div className='m_service_title'>Design An App That Attracts And<br></br>Retains User Attention</div>
  <div className='app_card_flex'>
   <div className='app_card'>
   <img src='/images/transform.3e20067a.svg.svg' alt=''/>
       <div className='app_title'>TRANSFORM YOUR IDEA<br></br> INTO A PRODUCT</div>
       <div className='app_title2'>Create and launch a successful MVP, and future-proof your product using the right web technology tools.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
       <img src='/images/admin.999a173a.svg.svg' alt=''/>
       <div className='app_title'>ATTRACT AND RETAIN<br></br> NEW CUSTOMERS</div>
      <div className='app_title2'>Cast a wider net to capture your target audience with an innovative, functional, reliable and truly helpful web app.</div> 
      <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
   <img src='/images/verified.1311b15e.svg.svg' alt=''/>
       <div className='app_title'>Prioritize app<br></br> security & stability</div>
       <div className='app_title2'>Adhere to best practices and international coding standards to prevent data leaks or security loopholes.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
   <img src='/images/verified.1311b15e.svg (1).svg' alt=''/>
       <div className='app_title'>Scale your way into<br></br> the digital market</div>
       <div className='app_title2'>Enable adding features & functionalities to your web solutions that customize app experience for users.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   
  </div>
</div>
  )
}

export default WebCards