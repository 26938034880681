import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ChooseCompany = () => {
    const data = [
        {
          image: "/images/div (11).svg",
          text: "Communicate",
          content:"Truthful communication plays an important role in the Software development process. Fortunately, we know both. We would like to fully integrate our clients into the development process. We update our clients regularly for each activity.",
        },
        {
            image: "/images/div (22).svg",
            text: "Scalability",
            content:"We prevent system downtime while ensuring a flawless user experience.",
          },
       
          {
            image: "/images/div (33).svg",
            text: "Technical Expertise",
            content:"We are a digital transformation company that provides state-of-the-art engineering solutions. In addition to bringing in-depth knowledge to specific areas of technology, we are also fully prepared to help you successfully bring your product to market.",
          },
          {
            image: "/images/div (4).svg",
            text: "One-Stop Shop",
            content:"We handle all aspects of your Software infrastructure including Web Development, App Development, Blockchain, Cloud & DevOps, Technology Consulting, Digital Marketing, Branding and any other related technology needs.",
          },
          {
            image: "/images/div (7).svg",
            text: "Customer service in-progress dashboard",
            content:"A customer service dashboard is a modern solution to monitor all relevant customer KPIs in an easy and accessible way. It is relevant for customer support agents, team leader and C-level executives at the same time and helps to generate a 360-degree customer view.",
          },
          {
            image: "/images/div (8).svg",
            text: "Dynamic project-change cost estimator",
            content:"Many costs can appear over the project management life cycle, and an accurate cost estimation method can be the difference between a successful plan and a failed one. Cost estimation, however, is easier said than done.",
          },
          {
            image: "/images/div (9).svg",
            text: "Product Marketing",
            content:"Product marketing services provided by our talented and highly experienced Team are available if you want to improve your current product marketing plan, or compose a completely new one.",
          },
         
       
       
      ];
      // responseive config
      const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1, // optional, default to 1.
        },
    
        tablet: {
          breakpoint: { max: 1024, min: 600 },
          items: 2,
          slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 500, min: 0 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
        },
      };

  return (
    <div className='choose'>
         <div className='choose_title'>WHY CHOOSE US</div>
         <div className='choose_title2'>6 Reasons to<br></br> Partner With Us</div>
         <Carousel
      // swipeable={false}
      // draggable={false}
      showDots={true}
      responsive={responsive}
      infinite={true}
      autoPlay={false}
      autoPlaySpeed={8328823}
      // keyBoardControl={true}
    //   customTransition="all .5"
      draggable={true}
      transitionDuration={300}
      containerClass="main_card2"
      // customLeftArrow={<CustomLeftArrow />}
      // customRightArrow={<CustomRightArrow />}
      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
    //   deviceType={props.deviceType}
      // dotListClass="custom-dot-list-style"
      //   itemClass="centerFlex"
    >
          {data.map((el) => {
            return (
                <div className='choose_cardd'>
                <div className='card_title_flex'>
                 <img src={el.image} alt=""/>
                 <div className='chooose_title2'>{el.text}</div>
                    </div>
                    <div className='choose_content'>{el.content}
</div>
</div>
            );
          })}
        </Carousel>
       
    </div>
  );

        }

export default ChooseCompany
