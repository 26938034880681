import React from 'react'

const Delivery = () => {
  return (
    <div className='quote'>
      <div className='quote_content'>
        <img src='/images/div.svg' alt=''/>
        <div className='delivery_title'>SERVICE DELIVERY<br></br>MODELS</div>
        <p className='quote_content'>We aim for a  transparent, cost effective, Better<br></br>and controlled & long-term relationship</p>
        <button className='quotebtn'>GET A QUOTE</button>
      </div>
      <div className='quote_imagess'>
        <img className='left_img' src='/images/01.png' alt=''/>
        <img className='quote_img' src='/images/02.png' alt=''/>
      </div>
    </div>
  )
}

export default Delivery
