import React from 'react'

const BolckCard = () => {
  return (
    <div className='AppCard'>
    <div className='m_service_title'>Design An App That Attracts And<br></br>Retains User Attention</div>
  <div className='app_card_flex'>
   <div className='app_card'>
   <img src='/images/transform.3e20067a.svg.svg' alt=''/>
       <div className='app_title'>COST <br></br>EFFECTIVENESST</div>
       <div className='app_title2'>Our team of developers is highly qualified <br></br>and dedicated to delivering quality development services to clients.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
       <img src='/images/admin.999a173a.svg.svg' alt=''/>
       <div className='app_title'>TRANSPARENCY AND<br></br> SECURITY</div>
      <div className='app_title2'>Blockchain technology provides the ability to fully ensure transparency, traceability, and security of the supply chain..</div> 
      <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
   <img src='/images/verified.1311b15e.svg.svg' alt=''/>
       <div className='app_title'>BOOST OPERATIONAL<br></br> EFFICIENCY</div>
       <div className='app_title2'>Blockchain improves and processes simplify your financial performance<br></br> in real-time - anywhere.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   <div className='app_card'>
   <img src='/images/verified.1311b15e.svg (1).svg' alt=''/>
       <div className='app_title'>SHARED DECENTRALIZED<br></br> LEDGER</div>
       <div className='app_title2'>Enable adding features & functionalities to your web solutions that customize app experience for users.</div>
       <button className='appbtn'>VIEW MORE</button>
   </div>
   
  </div>
</div>
  )
}

export default BolckCard