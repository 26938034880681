import React from 'react'

const Experties = () => {
  return (
    <div className='experties_section pb-4'>
  <h2 className='pb-3'>OUR EXPERTISE</h2>
  <p className='pb-4 our_text'>Today’s modern business environment requires<br></br> organizations to provide their customers with robust<br></br> and intelligent web and mobile solutions.</p>
      <div className='experties_section_images pb-4'>
        <div className='exper pr-3'><img src='/images/11667646_20945496 1.png' alt=''/>
        <div className='imgbtn1'>MAINTENANCE<br></br>How we can help</div></div>
        <div className='exper pl-4'><img src='/images/8609147_5836 1.png' alt=''/><div className='imgbtn'>CASE STUDIES<br></br>Case study stories</div></div>
      </div>
    </div>
  )
}

export default Experties
