import React from 'react'

const DevServices = () => {
  return (
    <div className='mobileServices'>
    <div className='m_service_title'>Cloud And Devops<br></br>
Services</div>
<div className='mobile_Services_flex'>
<div className='image_part'>
    <img src="/images/Group 15044-min.png" alt=''></img>
</div>
<div className='content_part'>

  
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>DevOps</div>
<div className='dev_content'>DevOps: DevOps is a system based on soft and fast systems<br></br> that aim to break down common barriers to allow for faster<br></br> software releases. The model usually has close collaboration,<br></br> systematic workflow, and continuous feedback.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Cloud Migration</div>
<div className='dev_content'>Cloud Migration: Before we move to your existing buildings in<br></br> the cloud, we first need to analyze everything that exists<br></br> now. We identify and monitor your entire portfolio of<br></br> applications from website and server to business and<br></br> customer management. We offer smooth cloud migration<br></br> with downtime at zero.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>AWS Cost Optimization</div>
<div className='dev_content'>AWS Cost Optimization: Amazon Web Services are now in<br></br> trend, providing complete services for hosting and also<br></br> building the application of future generation and their<br></br> related data/ AWS allows you to control costs and<br></br> streamline your spending while building modern applications,<br></br> which can be tailored to meet your needs..</div>

        </div>
    </div>
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>DevOps & Cloud Consulting</div>
<div className='dev_content1'>DevOps & Cloud Consulting: Make sure to improve your<br></br> software delivery,which is required to build a culture of speed<br></br> and the automation. You need to use the cloud capabilities,<br></br> speed up the release and each and every factor required.<br></br> We are here to help you! DevOps is the concept of<br></br> integration, we can use best modern approaches to integrate<br></br> IT operations with software development.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>MicroServices</div>
<div className='dev_content'>MicroServices: Microservice armature refers to a software<br></br> design style where different factors are made and stored<br></br> as standalone services. Each microservice is intended to<br></br> be small, manage the function of one business and be<br></br> used collectively without affecting the entire system.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Server Support</div>
<div className='service_card_mobiles_content'>Server Support: In cloudy environments, maintaining a<br></br> responsive IT infrastructure that fits your need for<br></br> development skills often requires dedicated, professional<br></br> support. We are secure and available every time with best<br></br> IT management systems and providing best quality services.</div>

        </div>
    </div>
</div>

</div>
<div  className='together_tag'> <div className='together_tag_title'>Let's work together</div>
<img className='arrowww' src='/images/Path 44027.svg' alt=''/></div>

</div>
  )
}

export default DevServices