import React from 'react'

const Projectprocces = () => {
  return (
    <div className='process'>
      <div className='quote_content'>
        <img src='/images/div.svg' alt=''/>
        <p className='project_content'>Project Implementation<br></br> Process</p>
       
      </div>
      <div className='quote_images'>
        <img className='project_img' src='/images/PngItem_1272669 1.png' alt=''/>
      </div>
    </div>
  )
}

export default Projectprocces