import React from 'react'

const Whychoose = () => {
  return (
    <div className='whychoose'>
        <div className='whychoose_flex'>
            <div className='whychoose_card'>
            <img src="/images/why2.svg" alt=''/>
            <div className='pl-4 text-center'>
                <div className='why_title'>0K</div>
                <div className='ehy_content'>ACTIVE INSTALLATION</div>
                </div>
            </div>
            <div className='whychoose_card'>
            <img src="/images/why2.svg" alt=''/>
                <div className='pl-4 text-center'>
                <div className='why_title'>97</div>
                <div className='ehy_content'>PROJECT COMPLETED</div>
                </div>
            </div>
            <div className='whychoose_card'>
                <img src="/images/why2.svg" alt=''/>
                <div className='pl-4 text-center'>
                <div className='why_title'>0K</div>
                <div className='ehy_content'>HAPPY CUSTOMERS</div>
                </div>
              
            </div>
        </div>
    </div>
  )
}

export default Whychoose