import React from 'react'
import { useNavigate } from 'react-router-dom';
const Services = () => {
  const Navigate = useNavigate ();
    // const data = [
    //     {
    //         image: "/images/div.png",
    //         title:"App Development",
    //         content:"Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…",
    //         view:"View More",

    //     },
    //     {
    //         image: "/images/div (1).png",
    //         title:"Web Development",
    //         content:"Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…",
    //         view:"View More",

    //     },
    //     {  
          
    //         image: "/images/div (2).png",
    //         title:"Web Design",
    //         content:"Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…",
    //         view:"View More",

    //     },
    // ]
  return (
    <div className='services'>
    <div className='quote_content'>
      <p className='service_title'>SERVICES</p>
      <p className='service_content'>We provide technology solutions<br></br> to companies worldwide</p>
      <p className='service_content2'>We are technology solutions providing<br></br> company all over the world doing over 40<br></br> years.</p>
      <button onClick={()=>Navigate("/experise")} className='servicesbtn'>Explore All Services</button>
    </div>
    <div className='quote_images'>
   
    <div className='pr-3 width_issu'> 
     <div className='card'>
    <div className='img_div'>
        <img src="/images/div.png" alt=""/>
</div>
<div className='services_title'>App Development</div>
<div className='services_content'>Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…</div>
<div onClick={()=>Navigate("/mobile-development")} className='services_view'>View More</div>
</div>
</div>
<div className='pr-3 width_issu'> 
<div className='card'>
    <div className='img_div'>
        <img src="/images/div (1).png" alt=""/>
</div>
<div className='services_title'>Web Development</div>
<div className='services_content'>Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…</div>
<div onClick={()=>Navigate("/web-development")} className='services_view'>View More</div>
</div>
</div>
<div className='width_issu'> 
<div className='card'>
    <div className='img_div'>
        <img src="/images/div (2).png" alt=""/>
</div>
<div className='services_title'>Web Design</div>
<div className='services_content'>Lorem ipsum dolor sit amet con setetur sadipscing elitr sed…</div>
<div onClick={()=>Navigate("/designing")} className='services_view'>View More</div>
</div>
</div>
    </div>
  </div>
  )
}

export default Services
