
import React from 'react'
import Carosuls from './Carosuls'

const TESTIMONIALS = () => {
  return (
 <div>
  <div className='text-center client_title'>TESTIMONIALS</div>
  <div className='text-center client_title2'>Clients love with us.</div>
  <Carosuls/>
 </div>
  )
}

export default TESTIMONIALS
