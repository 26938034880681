import React from 'react'

const DigitalOverview = () => {
    
  return (
    <>
   <div className='deve_services'>
        <div className='deve_title'>Our Bespoke Digital Services</div>
        <div className='deve_content'>We understand that successful solutions are only possible through<br></br> collaboration. We listen to our client’s ideas and needs, then build a solution<br></br> framework based on their feedback and our team’s extensive knowledge of<br></br> all things design and programming.</div>
        <div className='services_flex1'>
        
            <div className='service_card ' >
<img src='/images/d2.svg' alt=''/>
<div className='service_card_title'>Graphic Designing</div>
<div className='dev_content'>We see through your ideas and work our best to visualize them. With our exceptional services, all types of innovative and optimized graphic designs are now at your fingertips.</div>
<button className='view_btn'>View more</button>
            </div>
            <div  className='service_card two_card'>
            <img src='/images/d1.svg' alt=''/>
<div className='service_card_title'>Landing Page Design &<br></br> Development</div>
<div className='dev_content'>We create user-centric landing page designs that effectively compels your prospects to convert into potential<br></br> leads.</div>
<button className='view_btn'>View more</button>
            </div>
            <div  className='service_card two_card'>
            <img src='/images/d6.svg' alt=''/>
<div className='service_card_title'>SEO Services</div>
<div className='dev_content'>Our collaborative teams work tirelessly in order to give your website a competitive edge and help it secure a top-ranking position on the search engine result pages.</div>
<button className='view_btn'>View more</button>
            </div>
        </div>
        <div className='services_flex2'>
            <div  className='service_card two_card'>
            <img src='/images/d3.svg' alt=''/>
<div className='service_card_title'>PPc & Display Ads</div>
<div className='dev_content'>Increase your search engine visibility and get immediate results with Techorphic PPC management services.</div>
<button className='view_btn'>View more</button>
            </div>
            <div  className='service_card two_card'>
            <img src='/images/d4.svg' alt=''/>
<div className='service_card_title'>Website Maintenance and Support</div>
<div className='dev_content'>With an experienced team of developers, we provide affordable and reliable website maintenance services to help manage and maintain your website efficiently.</div>
<button className='view_btn'>View more</button>
            </div>
            <div   className='service_card two_card'>
            <img src='/images/d5.svg' alt=''/>
<div className='service_card_title'>SMM services</div>
<div className='dev_content'>Result-driven social media management and advertising strategies helping brands to grow on different social media channels.</div>
<button className='view_btn'>View more</button>
            </div>
        </div>
    </div></>
 
  )
}

export default DigitalOverview