import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
const ContactUs = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5sf3ers",
        "template_oeybt2o",
        form.current,
        "Mjffqs_0Urw24Jxzx"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        alert("Email Send Successfully"),
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset ();
  };
  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_5sf3ers', 'template_oeybt2o',e.target, 'Mjffqs_0Urw24Jxzx')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  //     e.target.reset()
  // };

  return (
    <div className='contact_us'>
        <div className='contact_title'>Get in <span className='toch'>Touch</span></div>
        <div className='contact_content'>We will reply to you as soon as possible.</div>
        <form ref={form} onSubmit={sendEmail}>
        <div className='contact_fields'>
          <input className='contact_input' type="text" placeholder='Name' name="name"/>
        </div>
        <div className='contact_fields'>
          <input className='contact_input' type="Email" placeholder='Email' name="email"/>
        </div>
        <div className='contact_fields'>
          <input className='contact_input' type="number" placeholder='Phone number' name="number"/>
        </div>
        <div className='contact_fields'>
          <input className='contact_input' type="text" placeholder='Massage' name="massage"/>
        </div>
        <div className='contact_btn_div'>
        <button input type="submit" value="Send" className='sendbtn'>SEND</button>
      </div>
        </form>
      
      {/* <div className='contact_down'>
      <Select className='contact_slct'
    showSearch
    
    placeholder="How did you find us?"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'Not Identified',
      },
      {
        value: '2',
        label: 'Closed',
      },
     
    ]}
  />
      </div> */}
  
      <div className='contact_items'>
        <div className='phone_item'>
            <img src='/images/phone.svg' alt=''/>
            <div className='pl-3 text-start'>
            <div className='phone_title'>PHONE</div>
            <div className='phone'>0000000</div>
            </div>
           
        </div>
        <div className='fax_item'>
        <img src='/images/fax.svg' alt=''/>
        <div className='pl-3 text-left'>
        <div className='phone_title'>FAX</div>
            <div className='phone'>000000000</div>
        </div>   
        </div>
        <div className='email_item'>
        <img src="/images/email.svg" alt=''/>
        <div className='pl-3 text-left'>   <div className='phone_title'>EMAIL</div>
            <div className='phone'>info@techorphic.com</div></div>
        </div>
      </div>
  
    </div>
  )
}

export default ContactUs