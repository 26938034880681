import React from 'react'

const Lifecycle = () => {
  return (
    <div className='deve_services2'>
    <div className='deve_title'>CUSTOM SOFTWARE<br></br> DEVELOPMENT LIFE CYCLE
</div>
    <div className='deve_content'>An effective development team can combine their knowledge of program<br></br> languages and techniques with advanced problem-solving. Problem-solving<br></br> is key to ensuring that all needs of your business, both known or unknown,<br></br> are identified during the planning phases of the development process.</div>
  <div className='lifecycle_flex'>
    <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='/images/planning.svg' alt=''/></div>
            <div className='life_title pl-4'>Planning</div>
           
           
        </div>
        <div className='life_content'>The planning stage is also called the feasibility<br></br> stage, we planned and discuss the feasibility,<br></br> scope, and objectives of the new system. At<br></br> this phase, we secure proper funding and<br></br> resources to make it possible.
</div>
</div>
        <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='/images/designing.83e1d44f.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Designing</div>
           
        </div>
        <div className='life_content'>After planning, now it's time to prototype your<br></br> idea, evaluate existing prototypes, perform<br></br> research and analysis for end-user<br></br> requirements.</div>
    </div>
    </div>
    <div className='lifecycle_flex pt-4 pb-4'>
    <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='/images/defining.1ce5d086.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Defining</div>
           
           
        </div>
        <div className='life_content'>The next step is to clearly define and<br></br> document the product requirements and get<br></br> them approved by the customer or the market<br></br> analysts. This is done through an SRS (Software<br></br> Requirement Specification) document which<br></br> consists of all the product requirements to be designed and developed during the project life cycle.
</div>
</div>
        <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='images/building.fc1791f7.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Building</div>
           
        </div>
        <div className='life_content'>Now it's time to build your project with the<br></br> exactly gathered information. At this stage, the<br></br> developers actually write the code and build<br></br> according to the earlier document.</div>
    </div>
    </div>
    <div className='lifecycle_flex pt-4 pb-4'>
    <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='/images/testing.0ddb6aa0.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Testing</div>
           
           
        </div>
        <div className='life_content'>The testing activities are mostly involved in all<br></br> the stages of SDLC, now it's time to test and<br></br> make sure there aren’t any bugs, experience<br></br> end user, and the software must match with<br></br> quality standards defined earlier.
</div>
</div>
        <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='/images/deployment.7f292793.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Deployment</div>
           
        </div>
        <div className='life_content'>Once the product is tested and ready to be<br></br> deployed it is released formally in the<br></br> appropriate market, now it's time to integrate<br></br> different modules and deployed them. It is<br></br> eventually integrated with the efforts of the<br></br> developer and finally installed.</div>
    </div>
    </div>
    <div className='lifecycle_flex pt-4 pb-4'>
    <div className='lifecycle_card pl-4 pr-4 pt-3 ml-4'>
        <div className='life_title_flex'>
            <div className='circle_img'> <img src='/images/maintainance.b67466fc.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Maintenance</div>
           
           
        </div>
        <div className='life_content'>The story doesn't end at integration and<br></br> deployment, maintenance is also an important<br></br> part. Now it's time to solve the issues the end-<br></br>user is facing. Perform all the required<br></br> changes and run-time errors.
</div>
</div>
        <div className=''>
        {/* <div className='life_title_flex'>
            <div className='circle_img'> <img src='images/building.fc1791f7.svg.svg' alt=''/></div>
            <div className='life_title pl-4'>Building</div>
           
        </div> */}
        {/* <div className='life_content'>Now it's time to build your project with the<br></br> exactly gathered information. At this stage, the<br></br> developers actually write the code and build<br></br> according to the earlier document.</div> */}
    </div>
    </div>
  </div>

  )
}

export default Lifecycle