import React from 'react'
import Navbar from '../components/Navbar'
import TopHeader from '../components/TopHeader'
import Hero from '../components/Hero'
import Experties from '../components/Experties'
import Techno from '../components/Techno'
import Quote from '../components/Quote'
import Services from '../components/Services'
import Choose from '../components/Choose'
import Delivery from '../components/Delivery'
import HireSection from '../components/HireSection'
import Clients from '../components/Clients'
import TESTIMONIALS from '../components/TESTIMONIALS'
import Portfolio from '../components/Portfolio'
import Office from '../components/Office'
import Projectprocces from '../components/Projectprocces'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'

const LandingPage = () => {
 
  return (
    <div className='clr_body'>
  <TopHeader/>
  <Navbar/>
  <Hero/>
  <Experties/>
  <Techno/>
  <Quote/>
  <Services/>
  <Choose/>
  <Delivery/>
  <HireSection/>
  <Clients/>
  <TESTIMONIALS/>
  <Portfolio/>
  <Office/>
  <Projectprocces/>
  <ContactUs/>
  <Footer/>
      </div>
  )
}

export default LandingPage
