import React from 'react'

const HireSection = () => {
  return (
    <>  <div className='hire_section'>
    <div className='hire_img'>
      <img src='/images/check.png' alt=''/>
    </div>
    <div className='hire_content'>
      <div className='hire_title1 pb-2'>HIRE TECHORPHIC</div>
      <div className='hire_title2'>AS YOUR OWN DELIVERY<br></br> CENTER</div>
      <img src='/images/greenborder.svg' alt=''/>
      <div className='hire_title3 pt-4 pb-2'>Hire top-class software developers & professionals ready to jump into your project from day one and work <br></br> as an extension of your team.</div>
      <div className='hire_title4 pt-3'>Fits Best for Agencies</div>
      <div className=''>
          <div className='greencircle'>
          <img src='/images/circle (3).svg' alt=''/>
          </div>
          <div className='content_hire'>Long-term Projects to Serve as Capacity Bridge</div>
      </div>
      <div className='check_div'>
          <div className='greencircle'>
          <img src='/images/circle (3).svg' alt=''/>
          </div>
          <div className='content_hire'>Become More Agile, Efficient, and Increase Speed to Market</div>
      </div>
      <div className='check_div'>
          <div className='greencircle'>
          <img src='/images/circle (3).svg' alt=''/>
          </div>
          <div className='content_hire'>Access to Highly Skilled and Competent Augmented Staff</div>
      </div>
      <div className='check_div'>
          <div className='greencircle'>
          <img src='/images/circle (3).svg' alt=''/>
          </div>
          <div className='content_hire'>Upscale and downscale on-demand</div>
      </div>
      <div className='check_div'>
          <div className='greencircle'>
          <img src='/images/circle (3).svg' alt=''/>
          </div>
          <div className='content_hire'>No Risk of Delays or Deadline Breach</div>
      </div>
      <div className='check_div'>
          <div className='greencircle'>
          <img src='/images/circle (3).svg' alt=''/>
          </div>
          <div className='content_hire'>Streamlined Business Operations with High Control</div>
      </div>
    </div>
  </div>
  <div className='hire_section2'>
    
      <div className='hire_content'>
        <div className='hire_title1 pb-2'>HIRE TECHORPHIC</div>
        <div className='hire_title2'>AS YOUR OWN DELIVERY<br></br> CENTER</div>
        <img src='/images/greenborder.svg' alt=''/>
        <div className='hire_title3 pt-4 pb-2'>Hire top-class software developers & professionals ready to jump into your project from day one and <br></br> work as an extension of your team.</div>
        <div className='hire_title4 pt-3'>Fits Best for Agencies</div>
        <div className='check_div'>
            <div className='greencircle'>
            <img src='/images/circle (3).svg' alt=''/>
            </div>
            <div className='content_hire'>Long-term Projects to Serve as Capacity Bridge</div>
        </div>
        <div className='check_div'>
            <div className='greencircle'>
            <img src='/images/circle (3).svg' alt=''/>
            </div>
            <div className='content_hire'>Become More Agile, Efficient, and Increase Speed to Market</div>
        </div>
        <div className='check_div'>
            <div className='greencircle'>
            <img src='/images/circle (3).svg' alt=''/>
            </div>
            <div className='content_hire'>Access to Highly Skilled and Competent Augmented Staff</div>
        </div>
        <div className='check_div'>
            <div className='greencircle'>
            <img src='/images/circle (3).svg' alt=''/>
            </div>
            <div className='content_hire'>Upscale and downscale on-demand</div>
        </div>
        <div className='check_div'>
            <div className='greencircle'>
            <img src='/images/circle (3).svg' alt=''/>
            </div>
            <div className='content_hire'>No Risk of Delays or Deadline Breach</div>
        </div>
        <div className='check_div'>
            <div className='greencircle'>
                <img src='/images/circle (3).svg' alt=''/>
            </div>
            <div className='content_hire'>Streamlined Business Operations with High Control</div>
        </div>
      </div>
      <div className='hire_img'>
        <img src='/images/Group 15028 (1).png' alt=''/>
      </div>
    </div></>
  
    
  )
}

export default HireSection
