import React from 'react'
import { Link } from 'react-router-dom'


const TopHeader = () => {
  return (
    <div className='top_header'>
        <div className='top_flex1'>
        <div className='top_section1'>
        <div className='top_ico'>
            <img src='/images/icon.svg' alt=''/>
        </div>
        <div className='top_content'>
            <div className='hdr_clr'>Contact us</div>
            <div className='hdr_clr'>+92 323 1404885</div>
        </div>
      </div>
      <div className='top_section2'>
        <div className='top_ico'>
        <img src='/images/second.svg' alt=''/>
        </div>
        <div className='top_content'>
        <div className='hdr_clr'>Have a question?</div>
            <div className='hdr_clr'>info@techorphic.com</div>
        </div>
      </div>
        </div>
        <div className='top_flex2'>
        {/* <div className='text-center mr-4'>
        <div className='top_ico'>
            <img src='/images/Survey.svg' alt=''/>
        </div>
            <div className='hdr_clr'>Case Study</div>
      </div> */}
      {/* <div className='text-center mr-4'>
        <div className='top_ico'>
            <img src='/images/Frame.svg' alt=''/>
        </div>
            <div className='hdr_clr'>Careers</div>
      </div> */}
       <Link to="/contact-us">
      <div className='text-center'>
        <div className='top_ico'>
            <img src='/images/contactus.svg' alt=''/>
        </div>
           <div className='hdr_clr'>Contact Us</div>
      </div></Link>
        </div>
   
    
    </div>
  )
}

export default TopHeader
