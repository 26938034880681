import React from 'react'

const BlockServices = () => {
  return (
    <div className='mobileServices'>
    <div className='m_service_title'>Blockchain Development<br></br>
Services<br></br>
</div>
<div className='mobile_Services_flex'>
<div className='image_part'>
    <img src="/images/Group 15038-min.png" alt=''></img>
</div>
<div className='content_part'>

  
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Blockchain Consultation</div>
<div className='dev_content'>Аt Techorphic Developers, we evaluate your existing solution's,<br></br> identify the need fоr а blосkсhаin solution's sрeсifiс tо your<br></br> business use саse аnd analyze hоw the blосkсhаin will аdd<br></br> vаlue tо your business. We рrороse аррrорriаte teсhnоlоgy<br></br> аnd possible sоlutiоns fоr the рrороsed рrоjeсt.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Wallet Development</div>
<div className='dev_content'>A blockchain wallet is also known as the wallet of<br></br> cryptocurrency, is used to transfer the transaction in digital<br></br> or crypto between a particular blockchain user to execute<br></br> smart blockchain contracts. The Fund also provides access<br></br> to its owner's digital transaction history, as well as its<br></br> balance.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Smart Contracts</div>
<div className='dev_content'>Smart contracts are another commendable feature in the<br></br> crypto industry, working on its superior services to improve<br></br> the performance of digital platforms. Now this is becoming<br></br> an essential part of every industry or company, they are<br></br> using blockchain technology for authenticity.</div>

        </div>
    </div>
    <div className=''>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Tokenization</div>
<div className='dev_content1'>Token making is the most accessible and cost-effective way<br></br> in marketing. Gain exposure to investors worldwide with<br></br> fewer legal requirements compared to bank loans,<br></br> crowdfunding, and IPOs. Your tokens are kept in stock, we<br></br> write a smart contract that releases tokens to your<br></br> investors once the conditions described earlier are met.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>NFT Marketplace</div>
<div className='dev_content'>"With NFT starting to gain popularity in recent times,<br></br> there is a need for digital space for NFT trading. Owners<br></br> interested in the crypto space can use the inexpensive<br></br> opportunity to introduce an exclusive NFT marketplace<br></br> where users can buy and sell NFTs. One can trade NFTs<br></br> with the help of cryptocurrency.</div>

        </div>
        <div className='service_card_mobiles'>
<div className='service_card_mobiles_title'>Gamification</div>
<div className='service_card_mobiles_content'>"Blockchain technology has opened up a whole new world<br></br> of video games.allowing for expanded proliferation<br></br>of gamification and the video games industry.</div>

        </div>
    </div>
</div>

</div>
<div  className='together_tag'> <div className='together_tag_title'>Let's work together</div>
<img className='arrowww' src='/images/Path 44027.svg' alt=''/></div>

</div>
  )
}

export default BlockServices