import React from 'react'

const AppCard = () => {
  return (
    <div className='AppCard'>
         <div className='m_service_title'>Design An App That Attracts And<br></br>Retains User Attention</div>
       <div className='app_card_flex'>
        <div className='app_card'>
        <img src='/images/transform.3e20067a.svg.svg' alt=''/>
            <div className='app_title'>FAST DEVELOPMENT</div>
            <div className='app_title2'>App Development provides you with a universal set of UI building blocks and a clear layered architecture.</div>
            <button className='appbtn'>VIEW MORE</button>
        </div>
        <div className='app_card'>
            <img src='/images/admin.999a173a.svg.svg' alt=''/>
            <div className='app_title'>FLEXIBLE UI</div>
           <div className='app_title2'>App Dev is customization and makes your UI flexible, allowing you to reuse a whole set of code in different layout configurations.</div> 
           <button className='appbtn'>VIEW MORE</button>
        </div>
        <div className='app_card'>
        <img src='/images/verified.1311b15e.svg.svg' alt=''/>
            <div className='app_title'>CROSS-PLATFORM</div>
            <div className='app_title2'>Build mobile apps that run on multiple device platforms– it provides fast development to help you market your app faster.</div>
            <button className='appbtn'>VIEW MORE</button>
        </div>
        <div className='app_card'>
        <img src='/images/verified.1311b15e.svg (1).svg' alt=''/>
            <div className='app_title'>BUILD ENGAGEMENT</div>
            <div className='app_title2'>Techorphic Developers create an app with Lower retention levels, strong mobile marketing, and pushes notifications</div>
            <button className='appbtn'>VIEW MORE</button>
        </div>
        
       </div>
    </div>
  )
}

export default AppCard