import React from 'react'
import { Link } from 'react-router-dom'
const Portfolio = () => {
    
  return (
    <div>
       <div className='client_title pt-4'>Company</div>
        <div className='client_title2'>PORTFOLIO</div>
        <div className='porfolio_flex'>
                <div className='porfolio_Card'>                        
                <img src="/images/ft.png" alt=''/>                
                <div class="card_title2">FinanceThat</div>
                <div className='port_title2 pb-2 pt-3'>Finance That is a marketplace for all your powersports and automotive buying, selling<br></br> and financing needs under the same roof.</div>
                <Link to="https://financethat.ca/"><button className='portbtn'>View More</button></Link>
              </div>
              <div className='porfolio_Card'>                        
                <img src="images/need.png" alt=''/>                
                <div class="card_title2">Need To Skip</div>
                <div className='port_title2 pb-2 pt-3'>If you're having trouble tracking down a homeowner, use our skip tracing services! Need to Skip uses top line technology to get best data.</div>
                <Link to="https://needtoskip.com/"><button className='portbtn'>View More</button></Link>
              </div>
              <div className='porfolio_Card'>                        
                <img src="/images/doc.png" alt=''/>                
                <div class="card_title2">Call On Doc</div>
                <div className='port_title2 pb-2 pt-3'>Get an online doctor visit anytime from anywhere when you need one from CallOnDoc.There is no wait to get an online doctor appointment today.</div>
                <Link to="https://www.callondoc.com/"><button className='portbtn'>View More</button></Link>
              </div>
        </div>
        </div>

  )
}


export default Portfolio
