import React, { useRef,useState } from "react";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
const ContactUss = () => {
    const Navigate = useNavigate ();
    const [isOpen, setIsOpen] = useState(false);
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
     setToggleState(index);
   };
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5sf3ers",
        "template_oeybt2o",
        form.current,
        "Mjffqs_0Urw24Jxzx"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        alert("Email Send Successfully"),
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset ();
  };
  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_5sf3ers', 'template_oeybt2o',e.target, 'Mjffqs_0Urw24Jxzx')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  //     e.target.reset()
  // };

  return (
    <>
    <div>
        <div className="main_nav">
        <div className="Navbar mt-0">
          <Link to="/">
            <img className="" src="/images/logo.svg" alt="" />
          </Link>
          <div className={`nav-items items ${isOpen && "open"}`}>
          <div class="dropdown">
            <Link to="/"><button class="dropbtn">Home</button></Link>
</div>
           
            <div class="dropdown">
             
              <button className={toggleState === 1 ? "dropbtn" : "activebtn"} onClick={() => toggleTab(1)} >Expertise</button>
            
  <img src='/images/dropsign.svg' alt=''/>
  <div class="dropdown-content">
  <Link className='drop_title' to="/mobile-development">App development</Link>
  <Link className='drop_title' to="/web-development">Web development</Link>
  <Link className='drop_title' to="/blockchain-development">Blockchain development</Link>
  <Link className='drop_title' to="/devops">Cloud services & Devops</Link>
  <Link className='drop_title' to="/qa">Quality Assurance</Link>
  <Link className='drop_title' to="/designing">Product Design</Link>
  </div>
</div>

            <div class="dropdown">
  <button onClick={()=>Navigate("/company")} class="dropbtn">Company</button>

</div>
            <div class="dropdown">
  <button  onClick={()=>Navigate("/digital-services")} class="dropbtn">Digital Services</button>
</div>
       <div className='search_bar'>
        <img src='/images/search.svg' alt=''/>
       </div>
       <div>  <Link to="Https://wa.me/3231404885"><button
              className="creat_accout1"
            >
     Hire Tech Staff
            </button></Link></div>
           <div>
            <Link to="contact-us">
           <button
              className="creat_accout"
            >
         Free Estimation
            </button></Link>
           </div>
         
          </div>
          <div
            className={`nav-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </div>


    <div style={{width:"100%",borderRadius:"0",paddingTop:"145px" }} className='contact_us tw_i mt-0 mb-0 '>
        <div className='contact_title'>Get in <span className='toch'>Touch</span></div>
        <div className='contact_content'>We will reply to you as soon as possible.</div>
        <form ref={form} onSubmit={sendEmail}>
        <div className='contact_fields'>
          <input className='contact_input' type="text" placeholder='Name' name="name"/>
        </div>
        <div className='contact_fields'>
          <input className='contact_input' type="Email" placeholder='Email' name="email"/>
        </div>
        <div className='contact_fields'>
          <input className='contact_input' type="number" placeholder='Phone number'/>
        </div>
        <div className='contact_fields'>
          <input className='contact_input' type="text" placeholder='Massage' name="massage"/>
        </div>
        <div className='contact_btn_div'>
        <button input type="submit" value="Send" className='sendbtn'>SEND</button>
      </div>
        </form>
      
      {/* <div className='contact_down'>
      <Select className='contact_slct'
    showSearch
    
    placeholder="How did you find us?"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'Not Identified',
      },
      {
        value: '2',
        label: 'Closed',
      },
     
    ]}
  />
      </div> */}
  
      <div className='contact_items'>
        <div className='phone_item'>
            <img src='/images/phone.svg' alt=''/>
            <div className='pl-3 text-start'>
            <div className='phone_title'>PHONE</div>
            <div className='phone'>0000000</div>
            </div>
           
        </div>
        <div className='fax_item'>
        <img src='/images/fax.svg' alt=''/>
        <div className='pl-3 text-left'>
        <div className='phone_title'>FAX</div>
            <div className='phone'>000000000</div>
        </div>   
        </div>
        <div className='email_item'>
        <img src="/images/email.svg" alt=''/>
        <div className='pl-3 text-left'>   <div className='phone_title'>EMAIL</div>
            <div className='phone'>info@techorphic.com</div></div>
        </div>
      </div>
  
    </div>
    </>
  )
}

export default ContactUss